import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import Bannerz from "../components/clientbanner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Loader from "./Loader";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>
        Home - Green Rental Cars
        </title>

        <meta
          name="description"
          content="Discover the best car rental deals with Green Rental Cars. Providing top-quality car renting services in Sigatoka, Port Denaru, Suva City, Vuda Marina and Coral Coast as well as Nadi airport and Nausori Airport. Your ultimate car renting experience awaits with Green Rental Cars Fiji!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Loader />
      <Hero />
      <PickCar />
      
     
      <ChooseUs />
      
      <Bannerz/>

      <Testimonials />
      <Banner />
      <Faq />
      <Footer />
    </>
  );
}

export default Home;
